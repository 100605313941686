import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { Breadcrumb, Articles } from '../components/media';
import * as styles from './templates.module.scss';

function AllMediaArticlesTemplate({ data, pageContext }) {
  const { allContentfulPageMediaDetail, pageData } = data;
  const articles = allContentfulPageMediaDetail?.nodes;

  return (
    <div className={styles.mediaContainer}>
      <div className={styles.titleContainer}>
        <Breadcrumb text="In the Media > More Articles" />
        <h1 className={styles.title}>{pageData.mediaPageTitle}</h1>
      </div>
      <Articles
        title={pageData.mediaPageSubtitle}
        articles={articles}
        pagination={pageContext?.pagination}
      />
    </div>
  );
}

export const query = graphql`
query ($limit: Int, $skip: Int){
  allContentfulPageMediaDetail(
    limit: $limit
    skip: $skip 
    sort: { fields: [allArticlesPageOrder, date], order: [ASC, DESC] }
    ){
    nodes{
      title
      uri
      description {
        description
      }
      date
      seo {
        metaDescription
        pageTitle
      }
      detailImage {
        title
        image {
          gatsbyImage(width: 260, placeholder: NONE, layout: FIXED)
        }
        altText
      }
      content {
        raw
      }
    }
  }
  pageData: contentfulPageMediaConfig(id: {eq: "2db7e1ea-f396-5883-948a-b2cac81d7916"}) {
    mediaPageSubtitle
    mediaPageTitle
  }
}
`;

AllMediaArticlesTemplate.propTypes = {
  data: object,
  pageContext: object,
};

AllMediaArticlesTemplate.defaultProps = {
  data: {},
  pageContext: {},
};

export default AllMediaArticlesTemplate;
export { Head } from '../components/seo/seo';
